document.addEventListener('DOMContentLoaded', () => {
  const searchContainers = document.querySelectorAll('.searchDiv');

  function openDropdown(container) {
    const button = container.querySelector('.searchIcon');
    const searchBar = container.querySelector('.search-bar');
    const overlay = container.querySelector('.overlay');
    const searchInput = searchBar.querySelector('.search-input');
    const searchResults = container.querySelector('.search-results');

    button.addEventListener('click', function () {
      const isExpanded = searchBar.classList.contains('expanded');

      if (isExpanded) {
        searchBar.classList.remove('expanded');
        container.classList.remove('search-active');
        button.classList.add('search-icon');
        button.classList.remove('search-icon-closed');
        searchInput.value = '';
        searchResults.style.display = 'none';
      } else {
        searchBar.classList.add('expanded');
        container.classList.add('search-active');
        button.classList.add('search-icon-closed');
        button.classList.remove('search-icon');
        searchInput.focus();
        searchResults.style.display = 'none';
      }
    });

    if (overlay) {
      overlay.addEventListener('click', function () {
        searchBar.classList.remove('expanded');
        container.classList.remove('search-active');
        button.classList.add('search-icon');
        button.classList.remove('search-icon-closed');
        searchInput.value = '';
        searchResults.style.display = 'none';
      });
    }

    if (searchInput) {
      searchInput.addEventListener('input', function () {
        searchInput.value = searchInput.value.replace(/[^a-zA-Z0-9\s]/g, '');

        if (searchInput.value.trim() === '') {
          searchResults.style.display = 'none';
        } else {
          searchResults.style.display = 'block';
          performSearch(searchInput.value.trim(), container);
        }
      });
    }
  }
  function performSearch(query, container) {
    const apiUrl = `${window._Torus.BFF_BASE_URL}/globalsearch?q=${query.toLowerCase()}&filter=stk&page=1&limit=20`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        const resultsContainer = container.querySelector(
          '.search-results .trending-list',
        );
        if (resultsContainer) {
          resultsContainer.innerHTML = '';

          const filteredData = data.filter(
            (item) => item.companyname || item.schName,
          );

          if (filteredData.length > 0) {
            filteredData.forEach((item) => {
              const resultItem = document.createElement('div');
              resultItem.className = 'category-item';
              resultItem.innerHTML = `
                                <p>${item.companyname || item.schName}</p>
                                <span class="item-type">${item.sectorname === 'ETF' ? 'ETF' : item.categoryname === 'Indices' ? 'Indices' : 'Stocks'}</span>
                            `;
              resultItem.style.userSelect = 'none';
              resultItem.style.cursor = 'pointer';

              resultItem.addEventListener('click', () => {
                const companyslug = item.companySlug;
                const sectorname = item.sectorname;
                const categoryname = item.categoryname;
                sectorname === 'ETF'
                  ? window.open(`/etf/${companyslug}`, '_blank')
                  : categoryname === 'Indices'
                    ? window.open(`/indices/${companyslug}`, '_blank')
                    : window.open(
                        `/stocks/${companyslug}-share-price`,
                        '_blank',
                      );
              });
              resultsContainer.appendChild(resultItem);
            });
          } else {
            resultsContainer.innerHTML = `
                            <div class="category-item">
                                <p>No results found</p>
                            </div>
                        `;
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching search results:', error);
        const resultsContainer = container.querySelector(
          '.search-results .trending-list',
        );
        if (resultsContainer) {
          resultsContainer.innerHTML = `
                        <div class="category-item">
                            <p>Error loading results</p>
                        </div>
                    `;
        }
      });
  }

  searchContainers.forEach(openDropdown);

  document.addEventListener('click', function (event) {
    searchContainers.forEach((container) => {
      const searchBar = container.querySelector('.search-bar');
      if (!container.contains(event.target)) {
        searchBar.classList.remove('expanded');
        container.classList.remove('search-active');
        const button = container.querySelector('.searchIcon');
        if (button) {
          button.classList.add('search-icon');
          button.classList.remove('search-icon-closed');
        }
        const searchInput = container.querySelector('.search-input');
        if (searchInput) {
          searchInput.value = '';
        }
        const searchResults = container.querySelector('.search-results');
        if (searchResults) {
          searchResults.style.display = 'none';
        }
      }
    });
  });
});
